@import "../shared/helper";

.icon {
  width: 1em;
  height: 1em;
  vertical-align: -0.15em;
  fill: currentColor;
  overflow: hidden;
}

.home {
  flex: 1;
  display: flex;
  flex-direction: column;

  header {
    text-shadow: 1px 1px 20px #77c0f9;
    color: #77c0f9;
    display: flex;
    justify-content: center;
    font-size: px(36);
    text-align: center;
    line-height: px(62);
    height: px(62);
    background-size: 120%;
    background-position: center;
  }



  .main {
    padding: px(10) 0;
    flex: 1;
    display: grid;
    grid-gap: px(20);
    grid-template:
  "box1 box2 box3 box5" 563fr
  "box1 box4 box4 box5" 151fr/305fr 192fr 592fr 300fr
  }

  .back-blue {
    background-color: rgba(15, 43, 87, 0.39);
  }

  .border-line {
    border: 1px solid #439cf6;
    position: relative;
    border-radius: 4px;

    &::after {
      content: '';
      position: absolute;
      left: 0;
      top: 0;
      right: 0;
      bottom: 0;

      box-shadow: 17px 0 0 -16px #0257a6,
      -17px 0 0 -16px #0257a6,
      0 17px 0 -16px #0257a6,
      0 -17px 0 -16px #0257a6,
      9px 0 0 -8px #318be3,
      -9px 0 0 -8px #318be3,
      0 9px 0 -8px #318be3,
      0 -9px 0 -8px #318be3;
      pointer-events: none;

    }

  }

  .section-1 {
    grid-area: box1;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    .chart-box {

      [class^="chart"] {
        background-color: rgba(15, 43, 87, 0.39);
      }


      .chart1, .chart2, .chart3 {
        height: px(220);
        margin-bottom: px(5);
        margin-top: px(10);
      }


    }

  }

  .section-2 {
    grid-area: box2;
    .chart5 {
      text-shadow: 0 0 5px #fffafa;

      background-color: rgba(15, 43, 87, 0.39);
      height: 100%;
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      align-items: center;
      font-size: px(26);

      > div {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;

        .count {
          background-color: rgba(75, 162, 255, 0.43);
          border-radius: 2px;
          width: 80%;
          margin-top: px(20);

          span {
            display: block;
            font-size: px(36);
            letter-spacing: px(12);
            text-indent: px(12);
            text-align: center;
          }
        }
      }
    }

  }

  .section-3 {
    grid-area: box3;
    >div{
      height: 100%;
      display: flex;
      flex-direction: column;
    }

    .map{
      margin-top: px(10);
      width: 100%;
      height: 100%;
    }
  }

  .title {
    font-size: px(20);
    box-shadow: 10px 3px 5px rgba(23, 125, 239, 0.76),
    0px -10px 3px rgba(23, 125, 239, 0.76) inset;
    text-shadow: 0 0 5px #fffafa
  }



  .chart4 {
    height: px(170);
    margin-top: px(10);
    padding: 0 px(10);
    display: flex;
    flex-direction: column;

    .table-box {
      width: 100%;
    }

    .table-head {
      height: px(30);
      padding: px(18) 0;
      color: #88d1e7;
      display: flex;
      text-align: center;
      align-items: center;
      border-bottom: #7eb999 1px solid;

      div {
        width: 25%;
      }
    }

    .table-box {
      .itemBox {
        @extend .table-head;
        border-bottom: none;
        color: whitesmoke;
      }
      .green{
        color: greenyellow;
      }
      width: 100%;
      height: 100%;
      overflow: scroll;

      table {
        text-align: center;
        width: 100%;
        table-layout: fixed;
      }
    }


  }




  .section-4 {
    grid-area: box4;

    > div {
      //border: 1px solid red;
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: space-between;



      > div:nth-child(2) {
        height: 80%;


        .remarks {
          margin: px(10);
        }

        .equipment {
          //border: 1px solid red;
          display: flex;
          justify-content: space-around;
          padding: 0 px(20);

          .item {
            position: relative;
            height: px(100);
            //border: 1px solid yellow;
            width: 14.2%;
            font-size: px(22);

            div:nth-child(1) {
              position: absolute;
              left: px(10);
            }

            div:nth-child(2) {
              &.shangsheng {
                color: red;
              }

              &.xiajiang {
                color: yellow;
              }

              div {
                position: absolute;
                right: px(-12);
                top: px(38);
              }

              .icon {
                position: absolute;
                right: px(18);
                top: px(50);
                font-size: px(40);
              }
            }

            div:nth-child(3) {
              position: absolute;
              left: 35%;
              top: 60%;
              transform: translateX(-50%);
            }

            &::after {
              content: '';
              position: absolute;
              left: 0;
              top: 0;
              height: 100%;
              border-left: 2px solid #4f9ea6;
            }
          }

        }
      }
    }

  }

  .section-5 {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    grid-area: box5;
    >div{

      >div{
        margin-top: px(10);
      }

    }
    font-size: px(22);

    .content-box {
      height: px(290);
      display: flex;
      flex-direction: column;


      > .box1 {
        margin: px(10) auto;
      }

      > .box2 {
        height: 20%;
        width: 90%;
        margin: 0 auto;
        border-radius: 2px;
        background-color: rgba(48, 79, 131, 0.82);
        text-align: center;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: px(42);
        letter-spacing: px(12);
        text-indent: px(12);
        text-align: center;
        margin-bottom: px(20);
      }

      .container {
        height: 50%;
        display: flex;
        flex-wrap: wrap;

        .count-box {
          width: 50%;
          height: 50%;

          display: flex;

          align-items: center;

          > div {
            height: 100%;
          }

          //border: solid 1px red;

          .icon-box {
            .icon {
              font-size: px(50);
              color: #5798bd;
              //border: solid 1px red;
            }

            width: 40%;
            text-align: center;
            display: flex;
            justify-content: center;
            align-items: center;
          }

          .content {
            width: 60%;
            display: flex;
            flex-direction: column;
            align-content: center;
            justify-content: center;

            div {
              width: 100%;
            }
          }
        }
      }
    }


    .notice{
      height: px(155);
      font-size: px(18);
      .content{
        padding:px(10) px(10);
        height: 100%;
        overflow: scroll;
      }

    }

    .chart9{
      height: px(160);
      .chart-line{
        height: 100%;
        width: 100%;
      }

    }
    .chart10{
      height: px(180);
    }
  }


}
