

body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
* {box-sizing: border-box;margin: 0; padding: 0; font-weight: normal;}
*::before {box-sizing: border-box;}
*::after {box-sizing: border-box;}

body{
    background-color: #0c2638;
    background-image: url("./images/back-img.png");
}

#root {
    color: whitesmoke;
    display: flex;
    flex-direction: column;
    margin: 0 auto;

}

.App {
    flex: 1;
    display: flex;
    flex-direction: column;
}
h1,h2,h3,h4{
    font-size: 16px;
}
::-webkit-scrollbar {
    display: none;
}
